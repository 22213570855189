import React from 'react';
import { Modal, Button } from 'reactstrap';

const ModuleSelectionPopup = ({ isOpen, toggle, navigateToModules }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <div className="modal-header">
        <h5 className="modal-title">Select a Module</h5>
        <button type="button" className="close" onClick={toggle}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Please select at least one module before proceeding.</p>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={navigateToModules}>
          Go to Module Selection
        </Button>
      </div>
    </Modal>
  );
};

export default ModuleSelectionPopup;