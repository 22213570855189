import React from "react";
import { Navigate } from "react-router-dom";

import LandingFour from "../pages/LandingFour/index";
import Pricing from "../pages/LandingFour/Pricing";
import AuthLogin from "../pages/LandingFour/AuthLogin";

// import MedicalWorkFlow from "../pages/LandingFour/medicalWorkflow";
// import ReactWorkflow from "../pages/LandingFour/ReactWorkFlow";

const routes = [
  //routes without Layout
  //Index Main
  {
    path: "/",
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: "/index", component: <LandingFour />, isTopbarDark: true },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/pricing" />,
  },
  { path: "/pricing", component: <Pricing />, isTopbarDark: true },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: "/auth-bs-login", component: <AuthLogin />, isWithoutLayout: true },

  { path: "/index", component: <LandingFour/>, isTopbarDark: true },
  // { path: "/medflow", component: <ReactWorkflow/>, isTopbarDark: true },
];

export default routes;
