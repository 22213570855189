import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// import images
import about from "../../assets/images/company/about2.png";

//Import Icons
import FeatherIcon from "feather-icons-react";

export default class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <span className="badge rounded-pill bg-soft-primary">
                  Features
                </span>
                <h4 className="title mt-3 mb-4">Quick gains from day one?</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  Start working with{" "}
                  <span className="text-primary fw-bold">emr</span> run your
                  medical practice without stress.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Card className="border-0 text-center features feature-primary feature-clean rounded p-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-envelope-lock d-block rounded h3 mb-0"></i>
                </div>

                <CardBody className="p-0 mt-4">
                  <h5>Fully Secured</h5>
                  <p className="text-muted mb-0">
                    Data security is our top priority.
                  </p>
                  <div className="mt-2">
                    <Link to="#" className="text-primary">
                      Read More{" "}
                      <i>
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm"
                        />
                      </i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Card className="border-0 text-center features feature-primary feature-clean rounded p-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-edit d-block rounded h3 mb-0"></i>
                </div>

                <CardBody className="p-0 mt-4">
                  <h5>Fewer clicks</h5>
                  <p className="text-muted mb-0">Intuitive and easy to use.</p>
                  <div className="mt-2">
                    <Link to="#" className="text-primary">
                      Read More{" "}
                      <i>
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm"
                        />
                      </i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Card className="border-0 text-center features feature-primary feature-clean rounded p-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-circle-layer d-block rounded h3 mb-0"></i>
                </div>

                <CardBody className="p-0 mt-4">
                  <h5>Fewer typing</h5>
                  <p className="text-muted mb-0">
                    Quality care should be all you do.
                  </p>
                  <div className="mt-2">
                    <Link to="#" className="text-primary">
                      Read More{" "}
                      <i>
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm"
                        />
                      </i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={about} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ms-lg-5">
                <div className="section-title mb-3">
                  <span className="badge rounded-pill bg-soft-primary">
                    Meet the future
                  </span>
                  <h4 className="title my-3">Introducing a new way</h4>
                  {/* <p className="text-muted para-desc">
                    Start working with{" "}
                    <span className="text-primary fw-bold">
                      medCloud
                    </span>{" "}
                    that can provide everything you need to generate awareness,
                    drive traffic, connect.
                  </p> */}
                  <p className="text-muted para-desc mb-0">
                    <span className="text-primary fw-bold">emr</span> software
                    is a well-crafted, user-friendly solution designed to help
                    hospital owners streamline their services. It not only
                    ensures smooth operations but also reduces costs by
                    maintaining secure patient data and eliminating the need for
                    on-premises server maintenance.
                  </p>
                </div>

                <ul className="list-unstyled text-muted">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Fully Responsive
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Multiple Layouts
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Suits Your Style
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Operation with ease
                  </li>
                </ul>

                <div className="mt-3">
                  <Link
                    to="https://www.entraid.cloud/emr.php"
                    target="_blank"
                    className="btn btn-primary m-1"
                  >
                    Read More{" "}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link>{" "}
                  {/* <Link
                    to="#"
                    className="btn btn-icon btn-pills video-play-icon btn-primary m-1"
                    onClick={this.openModal}
                  >
                    <i>
                      <FeatherIcon icon="video" className="icons" />{" "}
                    </i>
                  </Link>
                  <span className="fw-bold text-uppercase small align-middle ms-1">
                    Watch Now
                  </span> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="yba7hPeTSjk"
          onClose={() => this.setState({ isOpen: false })}
        />
      </React.Fragment>
    );
  }
}
