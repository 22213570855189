import React, { Component } from "react";
import KeyFeature from "../Agency/KeyFeature";
import Partners from "../Developer/Partners";
import Features from "./Features";
import Section from "./Section";
import TeamMember from "./TeamMember";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Popup from "../../components/Layout/popup";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sharedData: { modules: [], workflow: [] },
    };
  }

  setSharedData = (data) => {
    let allData = this.state.sharedData;
    if (data.modules !== undefined) {
      allData = { ...allData, modules: data.modules };
    } else if (data.workflow !== undefined) {
      allData = { ...allData, workflow: data.workflow };
    }
    this.setState({ sharedData: allData });
  };

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);

    var featurebox = document.getElementsByName("featurebox");
    for (var i = 0; i < featurebox.length; i++) {
      if (i !== 0) {
        featurebox[i].classList.add("mt-5");
        featurebox[i].classList.add("mt-sm-0");
      }
    }
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document
          .querySelector(".settingbtn")
          ?.classList.add("btn-soft-primary");
      }
    }
  };
  render() {
    const { sharedData } = this.state;

    console.log("sharedData updated:", sharedData);
    return (
      <React.Fragment>
        <Section sharedData={this.state.sharedData} />
        {/* Partners */}
        {/* <Partners /> */}
        {/* Features */}
        <section className="section">
          <Features />
        </section>
        <KeyFeature setSharedData={this.setSharedData} />

        {/* <TeamMember /> */}

        {/* Popup */}
        <Popup />
      </React.Fragment>
    );
  }
}
