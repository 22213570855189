// React Basic and Bootstrap
import React,{useState, useEffect} from "react";
import { Container, Row, Col, Media } from "reactstrap";
import { Link } from "react-router-dom";
import ReactFlow, { MiniMap, Controls } from 'react-flow-renderer';
import Workflow from "./Workflow";
import PropTypes from 'prop-types';

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import { check } from "prettier";


const KeyFeature = ({setSharedData}) => {
KeyFeature.propTypes = {
  setSharedData: PropTypes.func.isRequired
};
  const availableModules = ['Records','Scheduling','Billing','Cashier','Nursing','Encounter','Charting','Alerts','Automation','Clinics','Pharmacy','Out-Patient','Laboratory','Radiology','In-Patient','Surgery','Compliance','Analytics','Inventory','Insurance','Account','HR','Reports','Integration','Telemedicine','ICD-11','HL7/FHIR','E-Prescribing'];
  // const checkedModules = [true,true,true,true,true,true,true,true,true,true,true,false,false,true,false,true,false,true,false,false]
  
  const updateData = () => {
    setSharedData({'modules':selectedFeatures});
  };
  
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  
  const handleCheckboxChange = (event, featureName) => {
    setSelectedFeatures(prevSelectedFeatures => {
      if (event.target.checked) {
        return [...prevSelectedFeatures, featureName];
      } else {
        return prevSelectedFeatures.filter(name => name !== featureName);
      }
    });

  };

  useEffect(() => {
    // Log the selected features whenever it changes
    // console.log('Selected Features Updated:', selectedFeatures);
    updateData();
  }, [selectedFeatures,setSharedData]);
  

  return (
    <React.Fragment>
      
      <form className="section" id="medical">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Practice Modules"
            desc="that makes healthcare easy and efficient."
          />
          <Row>
            {availableModules.map((module, index) => (
              <Col lg={3} md={6} className="mt-4 pt-2" key={index}>
                <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle me-3">
                    {/* Uncomment and use FeatherIcon if needed */}
                    {/* <FeatherIcon
                      icon="monitor"
                      className="fea icon-ex-md text-primary"
                    /> */}
                    <input
                      type="checkbox"
                      // checked={checkedModules[index]}
                      onChange={(e) => handleCheckboxChange(e, module)}
                    />
                  </div>
                  <Media body>
                    <h4 className="title mb-0">{module}</h4>
                  </Media>
                </div>
              </Col>
            ))}
          </Row>

          <Row className="justify-content-center">
            <Col xs="12" className="text-center mt-4 pt-2">
              <button className="btn btn-primary">
                Submit
                {/* <i className="uil uil-angle-right-b align-middle"></i> */}
              </button>
            </Col>

            
          </Row>
        </Container>
      </form>

      {/* CREATING WORKFLOW */}

      <SectionTitle
            title="Create Your Workflow Here"
            // desc="that can provide everything you need to generate awareness, drive traffic, connect"
          />
      <Workflow setSharedData={setSharedData} />
    </React.Fragment>
  );
}

export default KeyFeature;
