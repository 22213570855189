import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Label, Row } from "reactstrap";
import ModuleSelectionPopup from "./Popup";
// import backGround_images
import build from "../../assets/images/real/build.png";
//Import Icons
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import baseUrl  from "./BaseUrl"

const Section = ({sharedData}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    address: "",
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  const buttonRef = useRef('#medical');

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleChange = (e) => {

    const { name, value } = e.target;
    
    if (name === 'phone') {
        // Simple regex to validate phone number format
        const phonePattern = /^\+?[1-9]\d{1,14}$/;
        
        if (!phonePattern.test(value)) {
            // Set error message if the phone number is invalid
            setErrorMessage("Please enter a valid phone number");
        } else {
            // Clear the error message if the phone number is valid
            setErrorMessage("");
        }
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Destructure form data (assuming formData is available in your component state)
    const { name, email, phone, address,company } = formData;
  
    // Check if modules are selected
    const modulesSelected = sharedData.modules.length > 0;
  
    if (!modulesSelected) {
      if (buttonRef.current) {
        buttonRef.current.click();
      }
    } else {
      try {
        // Prepare data payload
        let data = JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          company: company, // Example: Replace with your actual company value
          //message: 'string', // Example: Replace with your actual message value
          address: address,
          modules: JSON.stringify(sharedData.modules), // Ensure modules data is stringified properly
         // workflow: 'string' // Example: Replace with your actual workflow value
        });
  
        // Axios config object
        let config = {
          method: 'post',
          url: `${baseUrl}prospect`, // Replace with your actual endpoint
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
  
        // Make the Axios request
        const response = await axios(config);
  
        // Check if response status is 200
        if (response.status === 200) {
          console.log('Server response:', response.data);
          // Navigate to pricing or handle the response as needed
          //navigateToPricing();
        } else {
          console.error('Unexpected status code:', response.status);
          // Handle unexpected status codes
        }
      } catch (error) {
        console.error('Error sending data to the server:', error);
        // Handle the error as needed
      }
    }
  };
  
  const navigateToPricing = () => {
    const { name, email, phone, address } = formData;
    navigate(
      `/pricing?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}&address=${encodeURIComponent(address)}`
    );
  };

  const navigateToModules = () => {
    setIsPopupOpen(false);

    // if (buttonRef.current) {
    //   buttonRef.current.click();
    // }
    //  navigate("/index#medical");
  };


  return (
    <React.Fragment>
      <section
        className="bg-half-170 bg-light d-table w-100"
        style={{ background: `url(${build}) bottom no-repeat` }}
      >
        <Container>
          <Row className="align-items-center mt-5">
            <Col xl={8} lg={7} md={6}>
              <div className="title-heading">
                {/* <span className="badge rounded-pill bg-soft-success">Emr</span> */}
                <h4 className="heading my-3">Click less,<br />Type less, Care more</h4>
                <p className="para-desc text-muted">Remove stress, save time, and focus on quality care.</p>
                <div className="mt-4 pt-2">
                  <a href="#medical" ref={buttonRef} className="btn btn-primary m-1 invisible">
                    Select Practice modules
                  </a>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={5} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Card className="shadow rounded border-0 ms-lg-4">
                <CardBody>
                  <h5 className="card-title text-center">
                    Subscribe to get Started
                  </h5>
                  <Form className="login-form mt-4" onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Full name <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                            <input
                              type="text"
                              className="form-control ps-5"
                              placeholder="Full Name"
                              name="fullname"
                              value={formData.fullname}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Your Email <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                            <input
                              type="email"
                              className="form-control ps-5"
                              placeholder="Email"
                              name="email"
                              required="required"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Your Phone Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons"
                              />
                            </i>
                            <input
                              type="tel"
                              className="form-control ps-5"
                              placeholder="Phone Number"
                              name="phone"
                              pattern="^\+?[1-9]\d{1,14}$"
                              value={formData.phone}
                              onChange={handleChange}
                              required
                            />
                            {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Practice Name<span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="home"
                                className="fea icon-sm icons"
                              />
                            </i>
                            <input
                              type="text"
                              className="form-control ps-5"
                              placeholder="company"
                              name="company"
                              required="required"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Your Address <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="home"
                                className="fea icon-sm icons"
                              />
                            </i>
                            <input
                              type="address"
                              className="form-control ps-5"
                              placeholder="address"
                              name="Address"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={isCheckboxChecked}
                              onChange={handleCheckboxChange}
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              I Accept{" "}
                              <Link to="#" className="text-primary">
                                Terms And Condition
                              </Link>
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                          >
                            Select Practice modules
                          </button>
                          {/* <a href="#medical" ref={buttonRef} disabled={!isCheckboxChecked} className="btn btn-primary m-1">
                            Select Practice modules
                          </a> */}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <ModuleSelectionPopup
        isOpen={isPopupOpen}
        toggle={() => setIsPopupOpen(!isPopupOpen)}
        navigateToModules={navigateToModules}
      />
    </React.Fragment>
  );
};

export default Section;
