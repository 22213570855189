import React, { useState, useCallback } from 'react';
import SectionTitle from "../../components/Shared/SectionTitle";

import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Handle,
} from 'reactflow';
import 'reactflow/dist/style.css';
import PropTypes from 'prop-types';

let id = 0;
const getId = () => `dndnode_${id++}`;

const initialElements = [];
const initialNodes = [
  {
    id: '2',
    data: { label: 'Frontdesk' },
    position: { x: 50, y: 100 },
    className: 'light',
    style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 200, height: 200 },
  },
  {
    id: 'horizontal-1',
    sourcePosition: 'right',
    type: 'input',
    data: { label: 'Records' },
    position: { x: 20, y: 150 },
    parentId:'2'
  },
  {
    id: 'horizontal-1b',
    sourcePosition: 'right',
    type: 'input',
    data: { label: 'Appointment' },
    position: { x: 20, y: 50 },
    parentId:'2'
  },
  {
    id: '1',
    data: { label: 'Clinics' },
    position: { x: 350, y: 100 },
    className: 'light',
    style: { backgroundColor: 'rgba(255, 130, 205, 0.2)', width: 200, height: 200 },
  },
  {
    id: 'horizontal-2',
    sourcePosition: 'bottom',
    targetPosition: 'left',
    data: { label: 'Nursing' },
    position: { x: 20, y: 50 },
    parentId:'1',
  },
  {
    id: 'horizontal-3',
    sourcePosition: 'right',
    targetPosition: 'top',
    data: { label: 'Consultations' },
    position: { x: 20, y: 150 },
    parentId:'1',
  },
  {
    id: '3',
    data: { label: 'Service Centres' },
    position: { x: 700, y: 50 },
    // sourcePosition: 'right',
    targetPosition: 'bottom',
    className: 'light',
    style: { backgroundColor: 'rgba(255, 200, 2, 0.2)', width: 200, height: 230 },
  },
  {
    id: 'horizontal-5',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Pharmacy' },
    position: { x: 20, y: 100 },
    parentId:'3',
  },
  {
    id: 'horizontal-6',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Laboratory' },
    position: { x: 20, y: 150 },
    parentId:'3',
  },
  {
    id: 'horizontal-7',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Radiology' },
    position: { x: 20, y: 50 },
    parentId:'3',
  },
  {
    id: '4',
    data: { label: 'Account' },
    position: { x: 300, y: 350 },
    className: 'light',
    style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 200, height: 200 },
  },
  {
    id: 'horizontal-4a',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Cashier' },
    position: { x: 20, y: 100 },
    parentId:'4',
  },
  {
    id: 'horizontal-4b',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Billing' },
    position: { x: 20, y: 50 },
    parentId:'4',
  },
  {
    id: 'horizontal-4c',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Insurance' },
    position: { x: 20, y: 150 },
    parentId:'4',
  },
  {
    id: '5',
    data: { label: 'In-patient' },
    position: { x: 700, y: 350 },
    sourcePosition: 'top',
    // targetPosition: 'bottom',
    className: 'light',
    style: { backgroundColor: 'rgba(255, 250, 100, 70)', width: 200, height: 230 },
  },
  {
    id: 'horizontal-5a',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Ward' },
    position: { x: 20, y: 50 },
    parentId:'5',
  },
  {
    id: 'horizontal-5b',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Ward Round' },
    position: { x: 20, y: 100 },
    parentId:'5',
  },
  {
    id: 'horizontal-5c',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Procedures' },
    position: { x: 20, y: 150 },
    parentId:'5',
  },
  {
    id: '6',
    data: { label: 'Administration' },
    position: { x: 950, y: 50 },
    sourcePosition: 'top',
    // targetPosition: 'bottom',
    className: 'light',
    style: { backgroundColor: 'rgba(155, 250, 10, 70)', width: 200, height: 500 },
  },
  {
    id: 'horizontal-6a',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'HR' },
    position: { x: 20, y: 50 },
    parentId:'6',
  },
  {
    id: 'horizontal-6b',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Inventory' },
    position: { x: 20, y: 100 },
    parentId:'6',
  },
  {
    id: 'horizontal-6c',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Analytics' },
    position: { x: 20, y: 150 },
    parentId:'6',
  },{
    id: 'horizontal-6d',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Reports' },
    position: { x: 20, y: 200 },
    parentId:'6',
  },
  {
    id: 'horizontal-6e',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Integration' },
    position: { x: 20, y: 250 },
    parentId:'6',
  }
];

const initialEdges = [
  {
    id: 'horizontal-e1-2',
    source: 'horizontal-1',
    type: 'smoothstep',
    target: 'horizontal-2',
    animated: true,
  },
  {
    id: 'horizontal-e1b-2',
    source: 'horizontal-1b',
    type: 'smoothstep',
    target: 'horizontal-2',
    animated: true,
  },
  // {
  //   id: 'horizontal-e1-3',
  //   source: '2',
  //   type: 'smoothstep',
  //   target: 'horizontal-3',
  //   animated: true,
  // },
  {
    id: 'horizontal-e1-4',
    source: 'horizontal-2',
    type: 'smoothstep',
    target: 'horizontal-3',
    animated: true,
  },
  {
    id: 'horizontal-e4-3',
    source: '2',
    type: 'smoothstep',
    target: 'horizontal-4a',
    animated: true,
  },
  {
    id: 'horizontal-e4-2',
    source: '2',
    type: 'smoothstep',
    target: 'horizontal-4b',
    animated: true,
  },
  {
    id: 'horizontal-e3-5',
    source: 'horizontal-3',
    type: 'smoothstep',
    target: 'horizontal-5',
    animated: true,
  },
  {
    id: 'horizontal-e3-6',
    source: 'horizontal-3',
    type: 'smoothstep',
    target: 'horizontal-6',
    animated: true,
  },
  {
    id: 'horizontal-e5-7',
    source: 'horizontal-3',
    type: 'smoothstep',
    target: 'horizontal-7',
    animated: true,
  },
  {
    id: 'horizontal-e6-8',
    source: 'horizontal-3',
    type: 'smoothstep',
    target: 'horizontal-8',
    animated: true,
  },
  {
    id: 'horizontal-e5a',
    source: 'horizontal-3',
    type: 'smoothstep',
    target: 'horizontal-5a',
    animated: true,
    label: 'Admission',
    },
    {
      id: 'horizontal-e5b',
      source: '5',
      type: 'smoothstep',
      target: '3',
      animated: true,
    }
];

const CustomNode = ({ id, data }) => {
  const [label, setLabel] = useState(data.label);

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
    data.updateNodeLabel(id, event.target.value);
  };

  return (
    <div style={{ padding: 10, border: '1px solid #ddd', borderRadius: 5, position: 'relative' }}>
      <button
        style={{
          position: 'absolute',
          top: -10,
          right: -10,
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: 20,
          height: 20,
          cursor: 'pointer',
        }}
        onClick={() => data.deleteNode(id)}
      >
        X
      </button>
      <Handle type="target" position="top" style={{ borderRadius: 0 }} />
      <input
        type="text"
        value={label}
        onChange={handleLabelChange}
        style={{ width: '100%', border: 'none', textAlign: 'center' }}
      />
      <Handle type="source" position="bottom" style={{ borderRadius: 0 }} />
    </div>
  );
};

const nodeTypes = { customNode: CustomNode };
const Workflow = ({setSharedData}) => {

  Workflow.propTypes = {
    setSharedData: PropTypes.func.isRequired
  };

  const updateData = () => {
    setSharedData({'workflow':{'nodes':nodes,'edges':edges}});
  };
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  const onLoad = useCallback((rfi) => setReactFlowInstance(rfi), []);

  const handleAddNode = () => {
    // const newPosition = getNewNodePosition(nodes);
    // const newNode = {
    //   id: getId(),
    //   type: 'customNode',
    //   position: newPosition,
    //   data: { label: '', deleteNode, updateNodeLabel },
    // };
    // setNodes((nds) => [...nds, newNode]);
    updateData();
  };

  const getNewNodePosition = (nodes) => {
    const spacing = 50; // Adjust spacing as needed
    const lastNode = nodes[nodes.length - 1];
    if (!lastNode) {
      return { x: 250, y: 5 };
    }
    return { x: lastNode.position.x + spacing, y: lastNode.position.y + spacing };
  };

  const deleteNode = (id) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
  };

  const updateNodeLabel = (id, newLabel) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, label: newLabel };
        }
        return node;
      })
    );
  };
  const loadModules = (modules_list) => {
    modules_list.forEach(element => {
      const newPosition = getNewNodePosition(nodes);
      const newNode = {
        id: getId(),
        type: 'customNode',
        position: newPosition,
        data: { label: element, deleteNode, updateNodeLabel },
      };
      setNodes((nds) => [...nds, newNode]);
    });
  }

  return (
    <ReactFlowProvider>
      <div style={{ height: '65vh', width: '90%', margin: 'auto', marginBottom: '70px', paddingTop:'20px' }}>
        <button onClick={handleAddNode} style={{ position: 'absolute', zIndex: 10, backgroundColor: '#2f55d4', color: '#fff', border: 'none', padding: '10px 15px', borderRadius: '4px' }}>
          Add Modules
        </button>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onLoad={onLoad}
          minZoom={0.8}
          maxZoom={1.2}
          nodeTypes={nodeTypes}
          style={{ width: '100%', height: '100%' }}
        >
          {/* <MiniMap /> */}
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  );
};

export default Workflow;
